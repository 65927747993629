import { css } from '@emotion/react'
import {
    disabledBackground,
    disabledBorder,
    disabledColor,
} from '@parsley_design_systems/design-system'

const customTheme = css`
    :root {
        body[data-theme='light'] {

            --brand-primary:rgba(189, 54, 135, 1);
            --brand-secondary: rgba(152, 205, 214, 1);
            --brand-tertiary: rgba(28, 62, 63, 1);
            --brand-fourth: rgba(132, 103, 81, 1);
            --brand-fifth: rgba(245, 244, 196, 1);
            
            --text-default: rgba(19, 54, 55, 1);
            --text-subdued: rgba(51, 82, 82, 1);
            --text-heading: rgba(28, 62, 63, 1);
            --text-inverse: rgba(255, 255, 255, 1);
            --text-subdued-inverse: rgba(188, 198, 198, 1);
            --text-heading-inverse: rgba(215, 221, 222, 1);
            --utility: rgba(51, 95, 103, 1);
            --utility-2: rgba(78, 147, 159, 1);
            --utility-3: rgba(139, 138, 111, 1);
            --utility-4: rgba(205, 101, 163, 1);

            --background-default: rgba(255, 255, 255, 1);
            --background-default-secondary: rgba(229, 242, 245, 1);
            --background-default-tertiary: rgba(195, 226, 231, 1);
            --background-default-fourth: rgba(246, 248, 248, 1);
            --background-default-fifth: rgba(239, 241, 241, 1);
            --background-inverse: rgba(44, 82, 89, 1);
            --background-secondary-inverse: rgba(38, 71, 77, 1);
            --background-tertiary-inverse: rgba(33, 61, 66, 1);
            --background-modal: rgba(0,0,0,.6);
            --background-modal-off: rgba(0,0,0,0);
            --background-loading: rgba(215, 221, 222, 1);

            --interactive: rgba(173, 2, 105, 1);
            --interactive-secondary: rgba(19, 54, 55, 1);
            --interactive-inverse: rgba(255, 255, 255, 1);
            --interactive-secondary-inverse: rgba(249, 173, 218, 1);
            --interactive-background: rgba(173, 2, 105, 1);
            --interactive-background-inverse: rgba(249, 173, 218, 1);

            --link-default: rgba(155, 44, 111, 1);
            --link-visited: rgba(155, 44, 111, 1);
            --interactive-hover: rgba(142, 25, 95, 1);
            --interactive-active: rgba(108, 19, 72, 1);

            --selected: rgba(51, 95, 103, 1);
            --selected-secondary: rgba(19, 54, 55, 1);
            --selected-background: rgba(51, 95, 103, 1);
            --selected-background-inverse: rgba(99, 180, 193, 1);
            --selected-background-secondary: rgba(19, 54, 55, 1);
            --selected-background-secondary-inverse: rgba(188, 198, 198, 1);

            --border-form: rgba(88, 113, 114, 1);
            --border-presentation: rgba(215, 221, 222, 1);
            --border-form-error: var(--critical-default);
            --border-form-disabled: var(--disabled-border-color);
            --scroll-bar: rgba(110, 110, 110, 1);

            --opacity-background-default: rgba(0, 0, 0, var(--opacity-default)); 
            --opacity-background-1: rgba(0, 0, 0, var(--opacity-1)); 
            --opacity-background-2: rgba(0, 0, 0, var(--opacity-2)); 
            --opacity-background-3: rgba(0, 0, 0, var(--opacity-3)); 
            --opacity-background-4: rgba(0, 0, 0, var(--opacity-4)); 
            --opacity-background-5: rgba(0, 0, 0, var(--opacity-5)); 

            --box-shadow-small: 0 var(--s-5) var(--s-4) var(--opacity-background-4);
            --box-shadow-medium: 0 var(--s-4) var(--s0) var(--opacity-background-4);
            --box-shadow-large: 0 var(--s0) var(--s3) var(--opacity-background-4);
            --box-shadow-xlarge: 0 var(--s3) var(--s6) var(--opacity-background-4);

            --disabled-background-color: rgba(215, 221, 222, 1);
            --disabled-text: rgba(120, 141, 141, 1);
            --disabled-border-color: rgba(188, 198, 198, 1);

            --form-placeholder: rgba(127, 127, 127, 1);

            --critical-default: rgba(175, 28, 0, 1);
            --critical-background: rgba(253, 237, 234, 1);
            --critical-background-secondary: rgba(253, 237, 234, 1);
            --critical-text: rgba(175, 28, 0, 1);
            --warning-default: rgba(206, 113, 0, 1);
            --warning-background: rgba(252, 239, 224, 1);
            --warning-background-secondary: rgba(252, 239, 224, 1);
            --warning-text: rgba(166, 74, 0, 1);
            --success-default: rgba(11, 155, 117, 1);
            --success-background: rgba(226, 244, 239, 1);
            --success-background-secondary: rgba(226, 244, 239, 1);
            --success-text: rgba(9, 123, 93, 1);
            --information-default: rgba(12, 108, 205, 1);
            --information-background: rgba(231, 241, 251, 1);
            --information-background-secondary: rgba(231, 241, 251, 1);
            --information-text: rgba(12, 108, 205, 1);

            --data-visualisation-1-default: rgba(195, 0, 195, 1);
            --data-visualisation-1-background: rgba(251, 235, 251, 1);
            --data-visualisation-1-text: rgba(121, 0, 121, 1);
            --data-visualisation-2-default: rgba(113, 114, 9, 1);
            --data-visualisation-2-background: rgba(243, 243, 203, 1);
            --data-visualisation-2-text: rgba(68, 69, 5, 1);
            --data-visualisation-3-default: rgba(136, 76, 219, 1);
            --data-visualisation-3-background: rgba(244, 237, 254, 1);
            --data-visualisation-3-text: rgba(83, 46, 133, 1);
            --data-visualisation-4-default: rgba(139, 104, 31, 1);
            --data-visualisation-4-background: rgba(247, 240, 224, 1);
            --data-visualisation-4-text: rgba(84, 63, 19, 1);
            --data-visualisation-5-default: rgba(54, 125, 38, 1);
            --data-visualisation-5-background: rgba(232, 244, 229, 1);
            --data-visualisation-5-text: rgba(32, 75, 23, 1);
            --data-visualisation-6-default: rgba(182, 69, 122, 1);
            --data-visualisation-6-background: rgba(254, 235, 244, 1);
            --data-visualisation-6-text: rgba(110, 42, 74, 1);
            --data-visualisation-7-default: rgba(132, 102, 112, 1);
            --data-visualisation-7-background: rgba(243, 240, 241, 1);
            --data-visualisation-7-text: rgba(83, 60, 68, 1);
            --data-visualisation-8-default: rgba(0, 118, 158, 1);
            --data-visualisation-8-background: rgba(226, 243, 248, 1);
            --data-visualisation-8-text: rgba(0, 72, 96, 1);
            --data-visualisation-9-default: rgba(175, 83, 54, 1);
            --data-visualisation-9-background: rgba(255, 237, 231, 1);
            --data-visualisation-9-text: rgba(106, 50, 33, 1);
            --data-visualisation-10-default: rgba(115, 102, 156, 1);
            --data-visualisation-10-background: rgba(241, 240, 245, 1);
            --data-visualisation-10-text: rgba(71, 54, 125, 1);

            --form-background: var(--background-default);
            --form-input-background: var(--background-default);
            --form-label: var(--text-default);
            --form-input-text: var(--text-default);
            --form-help-text: var(--text-subdued);
            --form-input-background-error: var(--critical-background);
            --form-validation-text: var(--critical-default);
            --form-input-text-error: var(--text-default);
            --form-label-error: var(--text-default);
            --form-help-text-error: var(--text-subdued);
            --form-input-background-disabled: var(--disabled-background);
            --form-input-text-disabled: var(--disabled-text);
            --form-label-disabled: var(--disabled-text);
            --form-fieldset-label: var(--text-default);
            --form-selected-colour: var(--selected-background);

            --button-primary-background: var(--interactive);
            --button-primary-border: var(--interactive);
            --button-primary-label: var(--text-inverse);
            --button-primary-background-hover: var(--interactive-hover);
            --button-primary-border-hover: var(--interactive-hover);
            --button-primary-label-hover: var(--text-inverse);
            --button-primary-background-active: var(--interactive-active);
            --button-primary-border-active: var(--interactive-active);
            --button-primary-label-active: var(--text-inverse);
            --button-secondary-background: var(--background-default);
            --button-secondary-border: var(--interactive);
            --button-secondary-label: var(--interactive);
            --button-secondary-background-hover: var(--background-default);
            --button-secondary-border-hover: var(--interactive-hover);
            --button-secondary-label-hover: var(--interactive-hover);
            --button-secondary-background-active: var(--background-default);
            --button-secondary-border-active: var(--interactive-active);
            --button-secondary-label-active: var(--interactive-active);
            --button-tertiary-background: transparent;
            --button-tertiary-border: transparent;
            --button-tertiary-label: var(--interactive);
            --button-tertiary-background-hover: transparent;
            --button-tertiary-border-hover: transparent;
            --button-tertiary-label-hover: var(--interactive-hover);
            --button-tertiary-background-active: transparent;
            --button-tertiary-border-active: transparent;
            --button-tertiary-label-active: var(--interactive-active);

            --button-primary-background-disabled: var(--disabled-background);
            --button-primary-border-disabled: var(--disabled-border-color);
            --button-primary-label-disabled: var(--disabled-text);
            --button-secondary-background-disabled: var(--disabled-background);
            --button-secondary-border-disabled: var(--disabled-border-color);
            --button-secondary-label-disabled: var(--disabled-text);
            --button-tertiary-background-disabled: none;
            --button-tertiary-border-disabled: none;
            --button-tertiary-label-disabled: none;

            background-color: var(--background-default-tertiary);
            color: var(--text-default);
        }

        body[data-theme='dark'] {

            --brand-primary: rgba(249, 173, 218, 1);
            --brand-secondary: rgba(44, 82, 89, 1);
            --brand-tertiary: rgba(28, 52, 57, 1);
            --brand-fourth: rgba(167, 129, 102, 1);
            --brand-fifth: rgba(58, 58, 47, 1);

            --text-default: rgba(246, 248, 248, 1);
            --text-subdued: rgba(188, 198, 198, 1);
            --text-heading: rgba(215, 221, 222, 1);
            --text-inverse: rgba(246, 248, 248, 1);
            --text-subdued-inverse: rgba(188, 198, 198, 1);
            --text-heading-inverse: rgba(215, 221, 222, 1);

            --utility: rgba(99, 180, 193, 1);
            --utility-2: rgba(51, 95, 103, 1);
            --utility-3: rgba(139, 138, 111, 1);
            --utility-4: rgba(219, 145, 189, 1);

            --background-default: rgba(19, 54, 55, 1);
            --background-default-secondary: rgba(28, 62, 63, 1);
            --background-default-tertiary: rgba(39, 71, 72, 1);
            --background-default-fourth: rgba(28, 52, 57, 1);
            --background-default-fifth: rgba(33, 61, 66, 1);
            --background-inverse: rgba(19, 54, 55, 1);
            --background-secondary-inverse: rgba(28, 62, 63, 1);
            --background-tertiary-inverse: rgba(39, 71, 72, 1);
            --background-modal: rgba(255,255,255,.4);
            --background-modal-off: rgba(255,255,255,0);
            --background-loading: rgba(215, 221, 222, 1);

            --interactive: rgba(249, 173, 218, 1);
            --interactive-secondary: rgba(246, 248, 248, 1);
            --interactive-inverse: rgba(249, 173, 218, 1);
            --interactive-secondary-inverse: rgba(246, 248, 248, 1);
            --interactive-background: rgba(131, 3, 79, 1);
            --interactive-background-inverse: rgba(131, 3, 79, 1);

            --link-default: rgba(249, 173, 218, 1);
            --link-visited: rgba(249, 173, 218, 1);
            --interactive-hover: rgba(251, 209, 234, 1);
            --interactive-active: rgba(254, 235, 246, 1);

            --selected: rgba(152, 205, 214, 1);
            --selected-secondary: rgba(246, 248, 248, 1);
            --selected-background: rgba(62, 116, 126, 1);
            --selected-background-inverse: rgba(62, 116, 126, 1);
            --selected-background-secondary: rgba(19, 54, 55, 1);
            --selected-background-secondary-inverse: rgba(19, 54, 55, 1);

            --border-form: rgb(156, 171, 172);
            --border-presentation: rgba(66, 94, 95, 1);
            --border-form-error: var(--critical-default);
            --border-form-disabled: var(--disabled-border-color);
            --scroll-bar: rgba(139, 139, 139, 1);

            --opacity-background-default: rgba(0, 0, 0, var(--opacity-default)); 
            --opacity-background-1: rgba(0, 0, 0, var(--opacity-1)); 
            --opacity-background-2: rgba(0, 0, 0, var(--opacity-2)); 
            --opacity-background-3: rgba(0, 0, 0, var(--opacity-3)); 
            --opacity-background-4: rgba(0, 0, 0, var(--opacity-4)); 
            --opacity-background-5: rgba(0, 0, 0, var(--opacity-5)); 

            --box-shadow-small: 0 var(--s-5) var(--s-4) var(--opacity-background-4);
            --box-shadow-medium: 0 var(--s-4) var(--s0) var(--opacity-background-4);
            --box-shadow-large: 0 var(--s0) var(--s3) var(--opacity-background-4);
            --box-shadow-xlarge: 0 var(--s3) var(--s6) var(--opacity-background-4);

            --disabled-background-color: rgba(39, 71, 72, 1);
            --disabled-text: rgba(66, 94, 95, 1);
            --disabled-border-color: rgba(51, 82, 82, 1);

            --form-placeholder: rgba(127, 127, 127, 1);

            --critical-default: rgba(247, 180, 167, 1);
            --critical-background: var(--background-default-tertiary);
            --critical-background-secondary: rgba(115, 18, 0, 1);
            --critical-text: rgba(247, 180, 167, 1);
            --warning-default: rgba(241, 186, 118, 1);
            --warning-background: var(--background-default-tertiary);
            --warning-background-secondary: rgba(86, 48, 0, 1);
            --warning-text: rgba(241, 186, 118, 1);
            --success-default: rgba(135, 211, 190, 1);
            --success-background: var(--background-default-tertiary);
            --success-background-secondary: rgba(5, 65, 49, 1);
            --success-text: rgba(135, 211, 190, 1);
            --information-default: rgba(162, 200, 239, 1);
            --information-background: var(--background-default-tertiary);
            --information-background-secondary: rgba(7, 57, 110, 1);
            --information-text: rgba(162, 200, 239, 1);

            --data-visualisation-1-default: rgba(219, 80, 219, 1);
            --data-visualisation-1-background: rgba(106, 0, 106, 1);
            --data-visualisation-1-text: rgba(239, 174, 239, 1);
            --data-visualisation-2-default: rgba(143, 144, 11, 1);
            --data-visualisation-2-background: rgba(59, 60, 5, 1);
            --data-visualisation-2-text: rgba(203, 203, 27, 1);
            --data-visualisation-3-default: rgba(167, 108, 250, 1);
            --data-visualisation-3-background: rgba(72, 40, 115, 1);
            --data-visualisation-3-text: rgba(213, 184, 252, 1);
            --data-visualisation-4-default: rgba(175, 132, 39, 1);
            --data-visualisation-4-background: rgba(72, 54, 16, 1);
            --data-visualisation-4-text: rgba(223, 192, 126, 1);
            --data-visualisation-5-default: rgba(68, 158, 48, 1);
            --data-visualisation-5-background: rgba(28, 65, 20, 1);
            --data-visualisation-5-text: rgba(156, 210, 144, 1);
            --data-visualisation-6-default: rgba(229, 87, 153, 1);
            --data-visualisation-6-background: rgba(95, 37, 64, 1);
            --data-visualisation-6-text: rgba(253, 173, 210, 1);
            --data-visualisation-7-default: rgba(112, 81, 92, 1);
            --data-visualisation-7-background: rgba(72, 51, 59, 1);
            --data-visualisation-7-text: rgba(205, 193, 197, 1);
            --data-visualisation-8-default: rgba(4, 150, 198, 1);
            --data-visualisation-8-background: rgba(0, 62, 83, 1);
            --data-visualisation-8-text: rgba(139, 206, 229, 1);
            --data-visualisation-9-default: rgba(221, 105, 68, 1);
            --data-visualisation-9-background: rgba(92, 43, 28, 1);
            --data-visualisation-9-text: rgba(254, 178, 154, 1);
            --data-visualisation-10-default: rgba(143, 133, 176, 1);
            --data-visualisation-10-background: rgba(61, 44, 119, 1);
            --data-visualisation-10-text: rgba(198, 194, 215, 1);

            --form-background: var(--background-default);
            --form-input-background: var(--background-default);
            --form-label: var(--text-default);
            --form-input-text: var(--text-default);
            --form-help-text: var(--text-subdued);
            --form-input-background-error: var(--critical-background);
            --form-validation-text: var(--critical-default);
            --form-input-text-error: var(--text-default);
            --form-label-error: var(--text-default);
            --form-help-text-error: var(--text-subdued);
            --form-input-background-disabled: var(--disabled-background);
            --form-input-text-disabled: var(--disabled-text);
            --form-label-disabled: var(--disabled-text);
            --form-fieldset-label: var(--text-default);
            --form-selected-colour: var(--selected-background);

            --button-primary-background: transparent;
            --button-primary-border: var(--interactive);
            --button-primary-label: var(--interactive);
            --button-primary-background-hover: transparent;
            --button-primary-border-hover: var(--interactive-hover);
            --button-primary-label-hover: var(--interactive-hover);
            --button-primary-background-active: transparent;
            --button-primary-border-active: var(--interactive-active);
            --button-primary-label-active: var(--text-inverse);
            --button-secondary-background: transparent;
            --button-secondary-border: var(--interactive);
            --button-secondary-label: var(--interactive);
            --button-secondary-background-hover: transparent;
            --button-secondary-border-hover: var(--interactive-hover);
            --button-secondary-label-hover: var(--interactive-hover);
            --button-secondary-background-active: transparent;
            --button-secondary-border-active: var(--interactive-active);
            --button-secondary-label-active: var(--interactive-active);
            --button-tertiary-background: none;
            --button-tertiary-border: none;
            --button-tertiary-label: var(--interactive);
            --button-tertiary-background-hover: none;
            --button-tertiary-border-hover: none;
            --button-tertiary-label-hover: var(--interactive-hover);
            --button-tertiary-background-active: none;
            --button-tertiary-border-active: none;
            --button-tertiary-label-active: var(--interactive-active);

            --button-primary-background-disabled: var(--disabled-background);
            --button-primary-border-disabled: var(--disabled-border-color);
            --button-primary-label-disabled: var(--disabled-text);
            --button-secondary-background-disabled: var(--disabled-background);
            --button-secondary-border-disabled: var(--disabled-border-color);
            --button-secondary-label-disabled: var(--disabled-text);
            --button-tertiary-background-disabled: none;
            --button-tertiary-border-disabled: none;
            --button-tertiary-label-disabled: none;

            background-color: var(--background-default-tertiary);
            color: var(--text-default);
        }

        --black: rgba(0, 0, 0, 1);
        --white: rgba(255, 255, 255, 1);

        --font-plain: 'Ubuntu', sans-serif;
        --font-heading: 'Ubuntu', sans-serif;
        --font-weight-default: 400;
        --font-weight-medium: 500;
        --font-weight-semi-bold: 600;
        --font-weight-bold: 700;

        --ratio: 1.25;
        --line-height-none: 1;
        --line-height-small: var(--ratio);
        --line-height-default: calc(1.2 * var(--ratio));
        --line-height-large: calc(1.6 * var(--ratio));

        --s-6: calc(0.0625 * var(--s0));
        --s-5: calc(0.125 * var(--s0));
        --s-4: calc(0.25 * var(--s0));
        --s-3: calc(0.375 * var(--s0));
        --s-2: calc(0.5 * var(--s0));
        --s-1: calc(0.75 * var(--s0));
        --s0: 1rem;
        --s1: calc(1.25 * var(--s0));
        --s2: calc(1.5 * var(--s0));
        --s3: calc(2 * var(--s0));
        --s4: calc(2.5 * var(--s0));
        --s5: calc(3 * var(--s0));
        --s6: calc(4 * var(--s0));
        --s7: calc(5 * var(--s0));
        --s8: calc(7 * var(--s0));

        --ts7: var(--s6);
        --ts6: var(--s5);
        --ts5: var(--s4);
        --ts4: var(--s3);
        --ts3: var(--s2);
        --ts2: var(--s1);
        --ts1: calc(1.125 * var(--s0));
        --ts0: var(--s0);
        --ts-1: calc(0.875 * var(--s0));
        --ts-2: calc(0.75 * var(--s0));

        --text-x-small: var(--ts-2);
        --text-small: var(--ts-1);
        --text-medium: var(--ts0);
        --text-large: var(--ts1);

        --measure-small: 40ch;
        --measure-medium: 65ch;
        --measure-large: 80ch;
        --measure-max: 75em;
        --character-max-width: 80ch;

        --form-width-xxxs: calc(3 * var(--s0));
        --form-width-xxs: calc(4.5 * var(--s0));
        --form-width-xs: calc(7.5 * var(--s0));
        --form-width-s: calc(9 * var(--s0));
        --form-width-m: calc(10.5 * var(--s0));
        --form-width-l: calc(13.25 * var(--s0));
        --form-width-xl: calc(16 * var(--s0));
        --form-width-xxl: calc(24.5 * var(--s0));
        --form-width-xxxl: calc(33 * var(--s0));
        --form-width-full: 100%;

        --width-10: 10%;
        --width-15: 15%;
        --width-20: 20%;
        --width-25: 25%;
        --width-30: 30%;
        --width-33: 33%;
        --width-35: 35%;
        --width-40: 40%;
        --width-45: 45%;
        --width-50: 50%;
        --width-55: 55%;
        --width-60: 60%;
        --width-65: 65%;
        --width-67: 67%;
        --width-70: 70%;
        --width-75: 75%;
        --width-80: 80%;
        --width-85: 85%;
        --width-90: 90%;
        --width-100: 100%;

        --breakpoint-xs: 0;
        --breakpoint-s: 36em;
        --breakpoint-m: 48em;
        --breakpoint-l: 62em;
        --breakpoint-xl: 75em;
        --breakpoint-xxl: 100em;
        --breakpoint-xxxl: 120em;

        --min-width-xs: var(--breakpoint-xs);
        --min-width-s: var(--breakpoint-sm);
        --min-width-m: var(--breakpoint-md);
        --min-width-l: var(--breakpoint-lg);
        --min-width-xl: var(--breakpoint-xl);
        --min-width-xxl: var(--breakpoint-xxl);
        --min-width-xxxl: var(--breakpoint-xxxl);

        --max-width-xs: var(--breakpoint-xs);
        --max-width-s: var(--breakpoint-s);
        --max-width-m: var(--breakpoint-m);
        --max-width-l: var(--breakpoint-l);
        --max-width-xl: var(--breakpoint-xl);
        --max-width-xxl: var(--breakpoint-xxl);
        --max-width-xxxl: var(--breakpoint-xxxl);
        --max-width-ch-40: var(--measure-small);
        --max-width-ch-65: var(--measure-medium);
        --max-width-ch-80: var(--measure-large);

        --border-small: var(--s-6);
        --border-default: var(--s-5);
        --border-large: var(--s-4);
        --border-radius-small: var(--s-5);
        --border-radius-default: var(--s-4);
        --border-radius-large: var(--s-3);

        --z-index-default: auto;
        --z-index-1: 100;
        --z-index-2: 200;
        --z-index-3: 300;
        --z-index-4: 400;
        --z-index-5: 500;
        --z-index-6: 600;
        --z-index-7: 700;
        --z-index-8: 800;
        --z-index-9: 900;
        --z-index-10: 1000;

        --opacity-default: 1;
        --opacity-1: .8;
        --opacity-2: .6;
        --opacity-3: .4;
        --opacity-4: .2;
        --opacity-5: 0;

        line-height: var(--line-height-default);
        font-size: var(--text-medium);
        font-family: var(--font-plain);
    }

    :focus {
        outline: var(--border-default) solid var(--interactive);
        outline-offset: var(--border-default);
    }
    :focus:not(:focus-visible) {
        outline: none;
        outline-offset: 0;
    }
    :focus-visible {
        outline: var(--border-default) solid var(--interactive);
        outline-offset: var(--border-default);
    }

    :disabled {
        ${disabledBackground};
        ${disabledColor};
        ${disabledBorder};
        cursor: not-allowed;
    }

    body {
        overflow-x: hidden;
    }
    
    a {
        color: var(--link-default);
    }

    strong, b {
        font-weight: var(--font-weight-semi-bold);
    }

    h1, .h1{
        font-size:var(--ts4);
        line-height:var(--line-height-small);
        font-family:var(--font-heading);
        font-weight:var(--font-weight-medium);
        color:var(--text-heading);
        hyphens:auto;
    }
    h2, .h2 {
        font-size:var(--ts3);
        line-height: var(--line-height-small);
        font-family:var(--font-heading);
        font-weight:var(--font-weight-medium);
        color:var(--text-heading);
    }
    h3, .h3{
        font-size:var(--ts2);
        line-height: var(--line-height-small);
        font-family:var(--font-heading);
        font-weight:var(--font-weight-medium);
        color:var(--text-heading);
    }
    h4, .h4{
        font-size:var(--ts1);
        line-height: var(--line-height-small);
        font-family:var(--font-heading);
        font-weight:var(--font-weight-medium);
        color:var(--text-heading);
    }
    h5, .h5{
        font-size:var(--ts0);
        line-height: var(--line-height-small);
        font-family:var(--font-heading);
        font-weight:var(--font-weight-semi-bold);
        color:var(--text-heading);
    }
    h6, .h6{
	    font-size:var(--ts0);
        line-height: var(--line-height-small);
        font-family:var(--font-heading);
        font-weight:var(--font-weight-medium);
        color:var(--text-heading);
    }
    .text-large {
        font-size: var(--ts1);
    }
    .text-medium {
        font-size: var(--ts0);
    }
    .text-small {
        font-size: var(--ts-1);
    }
    .text-x-small {
        font-size: var(--ts-2);
    }
    dt {
        font-weight: var(--font-weight-medium);
    }
    ul {
        list-style: none;
    }
    ol.ordered-list {
        list-style: decimal;
        padding: 0 0 0 var(--s1);

        & > li::marker {
            color: var(--brand-tertiary);
        }
    }
    p, li {
        max-width: var(--character-max-width);
    }
    dt {
        font-weight: var(--font-weight-medium);
    }

    ul.bullet-list li,
    ol.bullet-list li,
    dl.bullet-list dd {
        padding-left: var(--s1);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: var(--s-5);
            top: var(--s-2);
            display: block;
            width: var(--s-5);
            height: var(--s-5);
            border-radius: 50%;
            border: var(--s-4) solid var(--utility);
            background-color: var(--utility);
        }

        & + li,
        & + dd,
        ul,
        ol,
        dl {
            margin-top: var(--s-3);
        }
    }

    hr {
        border: 0;
        height: 0;
        border-top-width: var(--s-6);
        border-top-style: solid;
        border-top-color: var(--border-presentation);
    }

    .vh {
        clip-path: inset(100%) !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        height: 1px !important;
        overflow: hidden !important;
        position: absolute !important;
        white-space: nowrap !important;
        width: 1px !important;
    }
`

export default customTheme